<template>
  <div class="px-8 mt-6 divContainer">
    <v-form ref="qrPayForm">
      <v-card class="amount-card">
        <v-card-text>
          Deposit amount
          <br />
          {{ amount }} {{ qrDataCurrency }}
        </v-card-text>
        <!--      <v-card-text>QR Code: {{ qrCode }}</v-card-text>-->
      </v-card>

      <v-card class="px-3 mt-6 qrInfoCard">
        <v-row>
          <v-col cols="6">
            <img class="qrCode mx-auto" :src="qrCode" />
          </v-col>
          <v-col class="infocard-right">
            Scan or screenshot QR code to payment app
          </v-col>
        </v-row>
        <v-divider class="divider"></v-divider>

        <v-row>
          <v-col cols="8">
            <span class="" style="color:grey">Or copy UPI Id</span>
            <br />
            <span>{{ qrDataPA }}</span>
          </v-col>
          <v-col cols="3" class="lastrow-right">
            <span class="copy" @click="copyUPI">Copy</span>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col cols="12">
          <p class=" pt-6 mb-2" style="font-weight: bold">
            Confirmation transaction
            <v-icon>mdi-help-circle-outline</v-icon>
          </p>
          <app-form-field class="utrNumber" ref="utrNumber" value="" :label="'12 Digits UTR Number'" :placeholder="'12 Digits UTR Number'" v-model="utrNumber" :rules="validator.utrRules(12, 16)">
            <template v-slot:form-field-append>
              <label @click="pasteClipboard">Paste</label>
            </template>
          </app-form-field>
        </v-col>
        <!--      <v-col cols="12"> 12 Digits UTR NUmber</v-col>-->
      </v-row>
      <v-row>
        <v-btn class="depoBtn full-width buttonPrimary" @click="submitUTR">Deposit</v-btn>
      </v-row>
    </v-form>
    <v-snackbar v-model="showAlert" :timeout="notiMsgTimeout" top centered light class="pa-0" width="fit-content">
      <v-alert
        class="mb-0 pa-1"
        dense
        v-model="showAlert"
        dismissible
        light
        close-icon="close"
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import { CHECK_UTR, RESET_CHECKUTR_RESPONSE } from '@/store/payment.module'
import { formValidator, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'qrPayProcess',
  data: () => ({
    notiMsgTimeout: 4500,
    amount: 200,
    qrCode: '',
    qrData: '',
    transID: '',
    qrDataPA: '',
    qrDataPN: '',
    qrDataCurrency: '',
    showAlert: false,
    utrNumber: '',
    validator: formValidator,
    alertMessage: '',
    alertSuccess: true
  }),
  computed: {
    checkUTRResponse() {
      return this.$store.state.payment.checkUTRResponse.complete
    },
    comShowAlert() {
      return this.showAlert
    }
  },
  watch: {
    comShowAlert() {
      if (!this.showAlert) {
        let utrResponse = this.$store.state.payment.checkUTRResponse
        if (utrResponse.success) {
          this.$router.push({ name: ROUTE_NAME.HOME })
        }
        this.$store.dispatch(`${RESET_CHECKUTR_RESPONSE}`)
      }
    },
    checkUTRResponse() {
      let response = this.$store.state.payment.checkUTRResponse
      if (response.complete) {
        this.alertSuccess = response.success
        this.alertMessage = response.message
        this.showAlert = true

        if (response.success) {
          //close popup /go to homepage
          setTimeout(() => {
            this.$store.dispatch(`${RESET_CHECKUTR_RESPONSE}`)
            this.$router.push({ name: ROUTE_NAME.HOME })
          }, this.notiMsgTimeout - 100)
        }
      }
    }
  },
  created() {
    this.amount = this.$route.query.amount
    this.qrCode = this.$route.query.qrCode
    this.qrData = this.$route.query.qrData
    this.transID = this.$route.query.transID
    if (this.qrData != '') {
      let arrQrData = this.qrData.split('?')
      if (arrQrData.length >= 2) {
        let qrDataQS = arrQrData[1].split('&')
        if (qrDataQS.length >= 1) {
          for (var i = 0; i < qrDataQS.length; i++) {
            if (qrDataQS[i].indexOf('pa=') != -1) {
              this.qrDataPA = qrDataQS[i].split('=')[1]
            }

            if (qrDataQS[i].indexOf('am=') != -1) {
              this.amount = qrDataQS[i].split('=')[1]
            }

            if (qrDataQS[i].indexOf('pn=') != -1) {
              this.qrDataPN = qrDataQS[i].split('=')[1]
            }

            if (qrDataQS[i].indexOf('cu=') != -1) {
              this.qrDataCurrency = qrDataQS[i].split('=')[1]
            }
          }
        }
      }
    }
  },
  methods: {
    pasteClipboard(){
      navigator.clipboard.readText().then(text => {
        this.utrNumber = text
      })
    },
    submitUTR() {
      if (this.$refs.qrPayForm.validate()) {
        let obj = {
          transactionId: this.transID,
          utrNumber: this.utrNumber
        }
        this.$store.dispatch(`${CHECK_UTR}`, { obj })
      }
      // this.$refs['utrNumber'].validate()
    },
    copyUPI() {
      navigator.clipboard.writeText(this.qrDataPA)
      this.alertMessage = locale.getMessage(`message.copiedUPI`)
      this.showAlert = true
    },
    showUTRResponseMsg() {}
  }
}
</script>

<style lang="scss">
.utrNumber{
  .v-input__append-inner{
    color: #2874a6 !important;
  }
}
.divContainer {
  font-size: 0.91rem;

  .depoBtn {
    border-radius: 10px;
  }
}
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}
.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.amount-card {
  background-color: #f2f4f4 !important;
}

.qrInfoCard {
  background-color: #d6eaf8 !important;

  .infocard-right {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lastrow-right {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;

    .copy {
      color: #2874a6;
    }
  }

  .divider {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.qrCode {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 10px;
  margin: 0 auto;
}

@media (max-width: 959px) {
  .qrCode {
    width: 100%;
    height: 100%;
  }
}
</style>
0
